export const environment = {
    production: false,
    instanceID: '00000000-0000-0000-0000-000000000000',
    tenantList: '[{"name":"default","guid":"00000000-0000-0000-0000-000000000000"},{"name":"one","guid":"00000000-0000-0000-0000-000000000001"},{"name":"two","guid":"00000000-0000-0000-0000-000000000002"},{"name":"three","guid":"00000000-0000-0000-0000-000000000003"}]'
};

export let API_CONFIG = {
    CORE_API: '/api/admin',
    STORE_API: '/api/store',
    IDENTITY_API: '/api/identity',
    ACCOUNTMANAGEMENT_API2: '/api',
    ECOMMERCE_API: '/api/store',
    ProtectEndpoints: '/api/*',
    ProtectEndpoints2: '/api/*',
    AzureB2C: {
        Scope: ["https://dbkdev.onmicrosoft.com/61a1d0fd-2ea1-4a2f-977d-ea3f19855059/Basic.ReadWrite"],
        ClientId: '61a1d0fd-2ea1-4a2f-977d-ea3f19855059',
        SignUpSignIn: "B2C_1_login",
        EditProfile: "B2C_1_profile",
        PasswordReset: "B2C_1_password",
        AuthorityDomain: "dbkdev.b2clogin.com",
        ServiceTenant: "dbkdev.onmicrosoft.com",
        SignUpSignInAuthority: "https://dbkdev.b2clogin.com/dbkdev.onmicrosoft.com/B2C_1_login",
        EditProfileAuthority: "https://dbkdev.b2clogin.com/dbkdev.onmicrosoft.com/B2C_1_profile",
        PasswordResetAuthority: "https://dbkdev.b2clogin.com/dbkdev.onmicrosoft.com/B2C_1_password",
    }
}

export enum build_Configurations {
    Name = 'DBK',
    color = 'green',
    logoUrl = 'assets/img/logo/dbk.png',
    ENV = 'DEV',
    AboutUs = "DBK Cloud DEVELOPMENT Enviroment",
    FavIcon = 'assets/img/logo/dbk.png',
    FavIconLocal = 'assets/img/logo/dbk.png'
}

export enum dataGrid {
    pageSize= 10
}

export let build_info = {
    BuildNumber: '',
    BuildDateTime: ''
}