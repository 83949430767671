export const environment = {
  production: false,
  instanceID: '#{INSTANCE_ID}#',
  tenantList: '#{TENANT_LIST}#'
};

export let API_CONFIG = {
  CORE_API: '#{CORE_API}#',
  STORE_API: '#{STORE_API}#',
  IDENTITY_API: '#{IDENTITY_API}#',
  ACCOUNTMANAGEMENT_API2: '#{ACCOUNTMANAGEMENT_API2}#',
  ECOMMERCE_API: '#{ECOMMERCE_API}#',
  ProtectEndpoints: '#{ProtectEndpoints}#',
  ProtectEndpoints2: '#{ProtectEndpoints2}#',
  AzureB2C: {
    Scope: ['#{AzureB2C_Scope}#'],
    ClientId: '#{AzureB2C_ClientId}#',
    SignUpSignIn: '#{AzureB2C_SignUpSignIn}#',
    EditProfile: '#{AzureB2C_EditProfile}#',
    PasswordReset: '#{AzureB2C_PasswordReset}#',
    AuthorityDomain: '#{AzureB2C_AuthorityDomain}#',
    ServiceTenant: "#{AzureB2C_ServiceTenant}#",
    SignUpSignInAuthority: '#{AzureB2C_SignUpSignInAuthority}#',
    EditProfileAuthority: '#{AzureB2C_EditProfileAuthority}#',
    PasswordResetAuthority: '#{AzureB2C_PasswordResetAuthority}#',
  }
}
export enum build_Configurations {
  Name = '#{Branding_Name}#',
  color = '#{Branding_Color}#',
  logoUrl = '#{Branding_LogoUrl}#',
  ENV = '#{Branding_ENV}#',
  AboutUs = '#{Branding_AboutUs}#',
  FavIcon = '#{Branding_FavIcon}#',
  FavIconLocal = '#{Branding_FavIcon}#'
}

export enum dataGrid {
  pageSize = 50
}

export let build_info = {
  BuildNumber: '',
  BuildDateTime: ''
}
